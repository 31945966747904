@font-face {
  font-family: "iconfont"; /* Project id 4412918 */
  src: url('//at.alicdn.com/t/c/font_4412918_1ad56mn7mkq.woff2?t=1708941764285') format('woff2'),
       url('//at.alicdn.com/t/c/font_4412918_1ad56mn7mkq.woff?t=1708941764285') format('woff'),
       url('//at.alicdn.com/t/c/font_4412918_1ad56mn7mkq.ttf?t=1708941764285') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dingjiaguanli:before {
  content: "\e637";
}

.icon-jishuzhichizhichizhinengkefuzhinengfuwuzhinengzhichi:before {
  content: "\e652";
}

.icon-zhinan:before {
  content: "\e8d0";
}

.icon-shiyongwendang:before {
  content: "\eb66";
}

.icon-anli:before {
  content: "\e648";
}

.icon-APIwendang:before {
  content: "\e7d9";
}

.icon-xiajiang:before {
  content: "\e691";
}

.icon-jinggao1:before {
  content: "\e600";
}

.icon-wentiliebiao:before {
  content: "\e6bf";
}

.icon-jinggao:before {
  content: "\e66a";
}
