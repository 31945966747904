#root {
	overflow: auto;
	height: 100vh;
}
#root::-webkit-scrollbar {
	width: 8px;
	height: 8px;
	border-radius: 4px;
}
.semi-layout {
	height: 100%;
}

.semi-layout .semi-navigation {
	height: 100%;	
}
.semi-layout .semi-navigation-horizontal {
	height: 60px;
}

body[theme-mode="dark"] {
	background-color: var(--semi-color-bg-0);
}

body[theme-mode="dark"] .semi-layout-header {
	color: white;
}
body[theme-mode="dark"] .ui.header {
	color: white;
}
body[theme-mode="dark"] .ui.header .sub.header{
	color: white;
}
body[theme-mode="dark"] .ui.form .field>label {
	color: white;
}

body[theme-mode="dark"] .ui.checkbox label, .ui.checkbox+label {
	color: white;
}

body[theme-mode="dark"] .form-context .select-context .label {
	color: white;
}

body[theme-mode="dark"] .custom-footer .version {
	color: white;
}
body[theme-mode="dark"] .header .label {
	color: white;
}

@media only screen and (max-width: 767px) {
  .semi-table-tbody,
  .semi-table-row,
  .semi-table-row-cell {
    display: block !important;
    width: auto !important;
    padding: 2px !important;
  }
  .semi-table-row-cell {
    border-bottom: 0 !important;
  }
  .semi-table-tbody > .semi-table-row {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .semi-space {
    /*display: block!important;*/
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 3px;
    column-gap: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .hide-on-mobile {
    display: none !important;
  }
}
